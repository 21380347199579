/* eslint "import/max-dependencies": ["error", { max: 21 }] */
import { useModalState } from "@clipboard-health/ui-react";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useAppConfiguration } from "@src/appV2/App/useAppConfiguration";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location";
import { type UnverifiedShift } from "@src/appV2/Shifts/UnverifiedShifts/types";
import { addHours, differenceInHours, differenceInMilliseconds, parseISO } from "date-fns";
import { useEffect, useState } from "react";

import { useCancelShift } from "../Shift/ShiftState/api/useCancelShift";
import {
  DEFAULT_TIMESHEET_LOCATION,
  useUpdateShiftTimecard,
} from "../Shift/ShiftState/api/useUpdateShiftTimecard";
import { useUploadShiftTimecardToS3 } from "../Shift/ShiftState/api/useUploadShiftTimecardToS3";
import { useSetShiftTimesheetAvailability } from "./api/useSetShiftTimesheetAvailability";
import { CancellationReasonDrawer } from "./modals/CancellationReasonDrawer";
import { ConfirmReuploadTimecardDialog } from "./modals/ConfirmReuploadTimecardDialog";
import { ForgotTimecardPhotoDialog } from "./modals/ForgotTimecardPhotoDialog";
import { MoreTimesheetOptionsDrawer } from "./modals/MoreTimesheetOptionsDrawer";
import { PickShiftTimesheetDrawer } from "./modals/PickShiftTimesheetDrawer";
import { SentTimecardPhotoAnotherWayDialog } from "./modals/SentTimecardPhotoAnotherWayDialog";
import { UploadShiftTimesheetDrawer } from "./modals/UploadShiftTimesheetDrawer";
import { UnverifiedShiftCard } from "./UnverifiedShiftCard";

interface UnverifiedShiftItemProps {
  shift: UnverifiedShift;
}

export function UnverifiedShiftItem(props: UnverifiedShiftItemProps) {
  const { shift } = props;

  const uploadModalState = useModalState();

  const { data: deviceGeoLocation } = useDeviceGeoLocationIfAllowed();
  const { mutateAsync: uploadShiftTimecardToS3, isLoading: isUploadingShiftTimecardToS3 } =
    useUploadShiftTimecardToS3({
      meta: {
        userErrorMessage: "Something went wrong while uploading timesheet",
      },
    });
  const { mutateAsync: updateShiftTimecard, isLoading: isUpdatingShiftTimecard } =
    useUpdateShiftTimecard({
      meta: {
        userSuccessMessage: "Successfully updated shift timesheet",
        userErrorMessage: "Something went wrong while updating shift timesheet",
      },
    });

  const [timesheetImage, setTimesheetImage] = useState<Blob>();
  const pickModalState = useModalState();

  const reuploadModalState = useModalState();

  const shiftVerificationTimeout = Number(shift.businessrules?.value ?? 0);
  const verificationTimeRemaining = differenceInMilliseconds(
    addHours(parseISO(shift.end), shiftVerificationTimeout),
    Date.now()
  );
  const [isShiftVerificationActive, setIsShiftVerificationActive] = useState(
    verificationTimeRemaining > 0
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShiftVerificationActive(false);
    }, Math.max(verificationTimeRemaining, 0));

    return () => {
      clearTimeout(timeout);
    };
  }, [verificationTimeRemaining]);

  const { data: agentData, isSuccess: isSuccessAgentData } = useAgentProfile();
  const {
    mutateAsync: setShiftTimesheetAvailability,
    isLoading: isLoadingSetTimesheetAvailability,
  } = useSetShiftTimesheetAvailability({
    meta: {
      userErrorMessage: "Something went wrong while updating timesheet",
    },
  });
  const setTimesheetUnavailable = async (reason: string) => {
    if (isSuccessAgentData) {
      await setShiftTimesheetAvailability({
        shiftId: shift._id,
        reason,
        agentId: agentData._id,
        agentName: agentData.name,
      });
    }
  };

  const moreOptionsModalState = useModalState();
  const forgotTimecardPhotoModalState = useModalState();
  const sentPhotoAnotherWayModalState = useModalState();
  const cancellationReasonModalState = useModalState();
  const { mutateAsync: cancelShift, isLoading: isLoadingCancelShift } = useCancelShift({
    meta: {
      userSuccessMessage: "Successfully cancelled shift",
      userErrorMessage: "Something went wrong while cancelling shift",
    },
  });

  const {
    data: appConfiguration,
    isLoading: isLoadingAppConfiguration,
    isSuccess: isSuccessAppConfiguration,
  } = useAppConfiguration();
  const hoursToShiftStart = differenceInHours(parseISO(shift.start), new Date());

  return (
    <>
      <UnverifiedShiftCard
        shift={shift}
        isShiftVerificationActive={isShiftVerificationActive}
        onUploadTimesheetClick={pickModalState.openModal}
        onReuploadTimesheetClick={reuploadModalState.openModal}
        onMoreOptionsClick={moreOptionsModalState.openModal}
      />
      <PickShiftTimesheetDrawer
        modalState={pickModalState}
        onImagePicked={(image) => {
          setTimesheetImage(image);
          pickModalState.closeModal();
          uploadModalState.openModal();
        }}
      />
      {timesheetImage && (
        <UploadShiftTimesheetDrawer
          modalState={uploadModalState}
          image={timesheetImage}
          isLoading={isUploadingShiftTimecardToS3 || isUpdatingShiftTimecard}
          onUpload={async () => {
            const timecard = await uploadShiftTimecardToS3({
              fileBlob: timesheetImage,
              shiftId: shift._id,
              type: timesheetImage.type,
            });
            await updateShiftTimecard({
              shiftId: shift._id,
              timecard: [timecard],
              location: [
                (
                  deviceGeoLocation?.geoLocation.longitude ?? DEFAULT_TIMESHEET_LOCATION.LONGITUDE
                ).toString(),
                (
                  deviceGeoLocation?.geoLocation.latitude ?? DEFAULT_TIMESHEET_LOCATION.LATITUDE
                ).toString(),
              ],
            });
            logEvent(APP_V2_APP_EVENTS.TIMESHEET_PHOTO_UPLOADED, {
              isInstantPay: shift.isInstantPay,
            });
            uploadModalState.closeModal();
          }}
        />
      )}
      <ConfirmReuploadTimecardDialog
        modalState={reuploadModalState}
        onConfirm={() => {
          reuploadModalState.closeModal();
          pickModalState.openModal();
        }}
      />
      <MoreTimesheetOptionsDrawer
        modalState={moreOptionsModalState}
        isLoading={isLoadingSetTimesheetAvailability}
        onForgotPhoto={async () => {
          await setTimesheetUnavailable(APP_V2_APP_EVENTS.CANCEL_TIMESHEET_PHOTO_FORGOTTEN);
          logEvent(APP_V2_APP_EVENTS.CANCEL_TIMESHEET_PHOTO_FORGOTTEN);
          moreOptionsModalState.closeModal();
          forgotTimecardPhotoModalState.openModal();
        }}
        onSentAnotherWay={async () => {
          await setTimesheetUnavailable(APP_V2_APP_EVENTS.CANCEL_TIMECARD_SENT_ANOTHER_WAY);
          logEvent(APP_V2_APP_EVENTS.CANCEL_TIMECARD_SENT_ANOTHER_WAY);
          moreOptionsModalState.closeModal();
          sentPhotoAnotherWayModalState.openModal();
        }}
        onDidNotWork={async () => {
          moreOptionsModalState.closeModal();
          cancellationReasonModalState.openModal();
        }}
      />
      <ForgotTimecardPhotoDialog modalState={forgotTimecardPhotoModalState} />
      <SentTimecardPhotoAnotherWayDialog modalState={sentPhotoAnotherWayModalState} />
      {isSuccessAppConfiguration && (
        <CancellationReasonDrawer
          modalState={cancellationReasonModalState}
          isLoading={
            isLoadingCancelShift || isLoadingSetTimesheetAvailability || isLoadingAppConfiguration
          }
          shiftAlreadyStarted={hoursToShiftStart < 0}
          isPossibleCallOff={
            hoursToShiftStart > 0 && hoursToShiftStart <= (appConfiguration.callOffWindow ?? 0)
          }
          isPossibleCancellation={
            hoursToShiftStart > (appConfiguration.cancellationHoursMin ?? 0) &&
            hoursToShiftStart <= (appConfiguration.cancellationHoursMax ?? 0)
          }
          shiftStartsInMoreThanADay={hoursToShiftStart >= 24}
          onSubmit={async (values) => {
            await cancelShift({
              shiftId: shift._id,
              reasonType: values.reason,
              reasonDescription: values.description,
              isNative: true,
            });
            logEvent(APP_V2_APP_EVENTS.CANCEL_SHIFT_NOT_ATTENDED);
            await setTimesheetUnavailable(APP_V2_APP_EVENTS.CANCEL_SHIFT_NOT_ATTENDED);
            cancellationReasonModalState.closeModal();
          }}
        />
      )}
    </>
  );
}
